// Changes doc title when browser tab isn't open
let docTitle = document.title;
window.addEventListener("blur", () => {
  document.title = "Ralph ELH | Click me back ;)";
});
window.addEventListener("focus", () => {
  document.title = docTitle;
});

const burgerElem = document.getElementById("burger");
burgerElem.addEventListener("click", animateNav);

const aboutElem = document.getElementById("about");
aboutElem.addEventListener("click", (evt) => {
  const navs = document.getElementsByClassName("nav");
  const nav = navs[0];
  if (nav.className.includes("expanded")) {
    animateBurger();
    nav.classList.toggle("nav--expanded");
  }
  magicRotation(evt);
});

const boxes = document.getElementsByClassName("box--me");
const boxElem = boxes[0];
boxElem.addEventListener("click", (evt) => {
  const navs = document.getElementsByClassName("nav");
  const nav = navs[0];
  if (nav.className.includes("expanded")) {
    animateBurger();
    nav.classList.toggle("nav--expanded");
  }
  magicRotation(evt);
});

const cards0Elem = document.getElementById("cards--0");
const cards1Elem = document.getElementById("cards--1");
const cards2Elem = document.getElementById("cards--2");
const cardsArr = [cards0Elem, cards1Elem, cards2Elem];

for (cards of cardsArr) {
  cards.addEventListener("click", (evt) => {
    animateCard(evt);
  });
}

// Animates Burger in nav
function animateBurger() {
  document.getElementById("burger").classList.toggle("animate-burger");
}

// Animates nav
function animateNav() {
  animateBurger();
  const navs = document.getElementsByClassName("nav");
  const nav = navs[0];
  nav.classList.toggle("nav--expanded");
}

// Animates work cards
async function animateCard(evt) {
  const element = evt.currentTarget;
  // disables event listener
  element.classList.toggle("disabled");
  if (element.classList.contains("solo")) {
    element.classList.toggle("solo");
    element.classList.toggle("shuffle");
  } else if (element.classList.contains("reverse-shuffle")) {
    element.classList.toggle("reverse-shuffle");
    element.classList.toggle("shuffle");
  } else if (element.classList.contains("shuffle")) {
    element.classList.toggle("shuffle");
    element.classList.toggle("reverse-shuffle");
  }
  await sleep(1000);
  // reactivates event listener
  element.classList.toggle("disabled");
}

// pauses function for ms period of time
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Animates box--me and its content
async function magicRotation(evt) {
  let boxes = document.getElementsByClassName("box--me");
  let fronts = document.getElementsByClassName("front");
  let backs = document.getElementsByClassName("back");
  let box = boxes[0];
  let front = fronts[0];
  let back = backs[0];

  // element clicked
  const element = evt.currentTarget;

  // disables event listener until animation ends
  element.classList.toggle("disabled");
  // adds animation class
  box.classList.toggle("rotateY3D");

  // first magic rotation
  if (front.classList.contains("solo")) {
    front.classList.toggle("solo");
    // fade out front & fade in back
    front.classList.toggle("fadeOut");
    back.classList.toggle("fadeIn");
    // allocates some time for animations above to kick in
    await sleep(250);
    front.style.display = "none";
    back.style.display = "block";
    // awaits animation to finish
    await sleep(750);
  }
  // if back displayed
  else if (!front.classList.contains("fadeOut")) {
    // removes current fade classes
    front.classList.toggle("fadeIn");
    back.classList.toggle("fadeOut");
    // activates new fades
    front.classList.toggle("fadeOut");
    back.classList.toggle("fadeIn");
    // time for fades to kick in
    await sleep(250);
    front.style.display = "none";
    back.style.display = "block";
    // awaits animation to finish
    await sleep(750);
  }
  // if front displayed
  else {
    // removes current fade classes
    front.classList.toggle("fadeOut");
    back.classList.toggle("fadeIn");
    // activates new fades
    back.classList.toggle("fadeOut");
    front.classList.toggle("fadeIn");
    // time for fades to kick in
    await sleep(250);
    back.style.display = "none";
    front.style.display = "block";
    // awaits animation to finish
    await sleep(750);
  }
  // removes animation class
  box.classList.toggle("rotateY3D");
  // reactivates event listener
  element.classList.toggle("disabled");
}

// Following code applies to the Projects slider section:

const slider = document.querySelector(".slider");
const trail = document.querySelector(".trail").querySelectorAll("div");
// Transform value
let value = 0;
// trail index number
let trailValue = 0;

// Function to slide forward
const slide = (condition) => {
  // update value and trailValue
  condition === "increase" ? initiateINC() : initiateDEC();
  // move slide
  move(value, trailValue);
};

// function for increase(forward, next) configuration
const initiateINC = () => {
  // Remove active from all trails
  trail.forEach((cur) => cur.classList.remove("active"));
  // increase transform value
  value === 80 ? (value = 0) : (value += 20);
  // update trailValue based on value
  trailUpdate();
};

// function for decrease(backward, previous) configuration
const initiateDEC = () => {
  // Remove active from all trails
  trail.forEach((cur) => cur.classList.remove("active"));
  // decrease transform value
  value === 0 ? (value = 80) : (value -= 20);
  // update trailValue based on value
  trailUpdate();
};

// function to transform slide
const move = (S, T) => {
  // transform slider
  slider.style.transform = `translateX(-${S}%)`;
  //add active class to the current child div of trail
  trail[T].classList.add("active");
};

// function to update trailValue based on slide value
const trailUpdate = () => {
  if (value === 0) {
    trailValue = 0;
  } else if (value === 20) {
    trailValue = 1;
  } else if (value === 40) {
    trailValue = 2;
  } else if (value === 60) {
    trailValue = 3;
  } else {
    trailValue = 4;
  }
};

// Next  and  Previous button function (SVG icon with different classes)
document.querySelectorAll(".arrow").forEach((cur) => {
  // Assign function based on the class Name("next" and "prev")
  cur.addEventListener("click", () =>
    cur.classList.contains("next") ? slide("increase") : slide("decrease")
  );
});

// function to slide when trail marker is clicked
const clickCheck = (e) => {
  // remove active class from all trail markers
  trail.forEach((cur) => cur.classList.remove("active"));
  // Get selected trail marker
  const check = e.target;
  // add active class
  check.classList.add("active");
  // Update slide value based on the selected trail marker
  if (check.classList.contains("marker1")) {
    value = 0;
  } else if (check.classList.contains("marker2")) {
    value = 20;
  } else if (check.classList.contains("marker3")) {
    value = 40;
  } else if (check.classList.contains("marker4")) {
    value = 60;
  } else {
    value = 80;
  }
  // update trail marker based on value
  trailUpdate();
  // transfrom slide
  move(value, trailValue);
};

// Add function to all trail markers
trail.forEach((cur) => cur.addEventListener("click", (ev) => clickCheck(ev)));
